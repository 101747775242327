export const ROLLUPS_CONFIG = {
  testnet: [
    {
      name: 'Eternal',
      appId: '18',
      address: '5CocSpj62xG11MAkQMzsd7h8wRoxx1E44f8tJUUrPaWQ4opj',
      logo: null,
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Neova',
      appId: '34',
      address: '5GEQ6S3vpSFjYCqsrndQhcPL3sh8uAYbpeCiZFhF4u9EjK6F',
      logo: null,
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Lens',
      appId: '247',
      address: '5EHQtLHiLZsuJoa1SErufLdJQG8bRqoTVAQiXGhP3XsbeHMj',
      logo: "/logos/rollups/lens.jpg",
      website: 'https://www.lens.xyz/',
      github: 'https://github.com/lens-protocol',
      explorer: 'https://block-explorer.testnet.lens.dev/'
    },
    {
      name: 'Op-Alt-DA',
      appId: '57',
      address: '5FWWnzg9TpdUZx4D8TZxsgaGjDg1zvW17L6sqjKWtru13T4U',
      logo: null,
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Soon',
      appId: '288',
      address: '5FKuKhRfV7zu6jrtTwMf4EbNVLEApiJPWhUvGmgsrYRNmtro',
      logo: "/logos/rollups/soon.png",
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Pivotal',
      appId: '75',
      address: '5D5HufUivu1S7snNh6DdyitVyUFjQZhhJaPbPUnJi3kCXg3J',
      logo: null,
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Rooch',
      appId: '222',
      address: '5GWHjsx9URmAdW6PRdShMdq2PFPq4nJimk577KKyCpCa4K1o',
      logo: "/logos/rollups/rooch.jpg",
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Sophon',
      appId: '102',
      address: '5HRQKm9h4oqbvvU4fyFYCcia95PrMNNDhTPgsUDs5KSK15zA',
      logo: '/logos/rollups/sophon.jpg',
      website: null,
      github: null,
      explorer: null
    },
    {
      name: 'Space and Time',
      appId: '242',
      address: '5H9f86dfSvryinLECjEtXxJuEwrNcCLSnL7f2NtQ4mm63d1L',
      logo: '/logos/rollups/spaceandtime.jpg',
      website: 'https://www.lens.xyz/',
      github: 'https://github.com/lens-protocol',
      explorer: 'https://sxt-testnet-avail.explorer.caldera.xyz/'
    },
    {
      name: 'Orbit Rollup',
      appId: '107',
      address: '5CouPui3BUJu68bUCEMhyfgC5jMcLH8teJaiXXz3jwGxPwWQ',
      logo: null,
      website: null,
      github: null,
      explorer: null
    }
  ],
  mainnet: [
    {
      name: 'Sophon',
      appId: '17',
      address: '5FsjDzdeUbgYZPexD7gL1D35yX1CJQfsL98HCLkPHS9og4QF',
      logo: '/logos/rollups/sophon.jpg',
      website: 'https://sophon.xyz',
      github: 'https://github.com/sophon-org',
      explorer: 'https://explorer.sophon.xyz/'
    },
    {
      name: 'Rooch',
      appId: '25',
      address: '5GWHjsx9URmAdW6PRdShMdq2PFPq4nJimk577KKyCpCa4K1o',
      logo: '/logos/rollups/rooch.jpg',
      website: 'https://rooch.network',
      github: 'https://github.com/rooch-network',
      explorer: 'https://roochscan.io/'
    },
    {
      name: 'Odysphere',
      appId: '32',
      address: '5HEuY4nfbT8zEYsRGS4EvrbAWNgmnLdJFgmtw9hx8dtMwfmR',
      logo: '/logos/rollups/odysphere.png',
      website: 'https://odysphere.io/',
      github: 'https://github.com/Odysphere',
      explorer: 'https://portal.dymension.xyz/rollapps/odysphere_224126-1/dashboard'
    },
    {
      name: 'Soon',
      appId: '31',
      address: '5EZZm8AKzZw8ti9PSmTZdXCgNEeaE3vs5sNxqkQ6u5NhG8kT',
      logo: "/logos/rollups/soon.png",
      website: 'https://soo.network/',
      github: 'https://github.com/soonlabs',
      explorer: 'https://explorer.svmbnbmainnet.soo.network/'
    },
    {
      name: 'Lens',
      appId: '26',
      address: '5C7yF9M6JHza8m8MxF1Ljc1msaaCExuW3pCFsBJSgFZMkrYw',
      logo: "/logos/rollups/lens.jpg",
      website: 'https://www.lens.xyz/',
      github: 'https://github.com/lens-protocol',
      explorer: 'https://block-explorer.testnet.lens.dev/'
    },
    {
      name: 'Up or Down',
      appId: '30',
      address: '5CAEC8pGrkwrrjYcUTG2Lx5upPBiquTdY4vgWPvLQQJRLi9m',
      logo: "/logos/rollups/upordown.jpg",
      website: 'https://upordown.network/',
      github: 'https://docs.upordown.network/',
      explorer: 'https://portal.dymension.xyz/rollapps/upordowngame_689603-1/dashboard'
    },
    {
      name: 'Skate',
      appId: '19',
      address: '5DaNuvMWnbP3ywbtPDDe2kTSXvtE5npXDcmay6QUYZrGKQHJ',
      logo: '/logos/rollups/skate.jpeg',
      website: 'https://www.skatechain.org/',
      github: 'https://github.com/Skate-Org',
      explorer: 'https://scan.skatechain.org/'
    }
  ]
};
