import axios from 'axios';
import { ROLLUPS_CONFIG } from './config/rollups';

// GraphQL endpoint configuration
const getGraphQLEndpoint = (network) => {
  return network === 'testnet' 
    ? 'https://turing-indexer.avail.so/'
    : 'https://indexer.avail.so/';
};

// Convert bytes to human readable format with better precision for large numbers
const formatBytes = (bytes) => {
  if (!bytes || bytes === "0") return '0 Bytes';
  
  // Parse the input as a number, handling string inputs
  const bytesNum = typeof bytes === 'string' ? parseFloat(bytes) : bytes;
  if (isNaN(bytesNum)) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  
  // Handle very large numbers
  if (bytesNum === 0) return '0 Bytes';
  const i = Math.floor(Math.log(bytesNum) / Math.log(k));
  
  // Use more decimal places for larger units
  const decimals = i > 0 ? 2 : 0;
  const formatted = parseFloat((bytesNum / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[Math.min(i, sizes.length - 1)];
  
  return formatted;
};

// Format AVAIL amount with appropriate precision
const formatAvailValue = (amount) => {
  if (!amount) return '0 AVAIL';
  
  // Parse the input as a number, handling string inputs
  const value = typeof amount === 'string' ? parseFloat(amount) : amount;
  if (isNaN(value)) return '0 AVAIL';
  
  // Always use 2 decimal places for consistency
  return `${value.toFixed(2)} AVAIL`;
};

// Cache configuration
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const TABLE_CACHE_DURATION = 30 * 1000; // 30 seconds for table data
const cache = new Map();

const getCacheKey = (network, appId, address) => `${network}-${appId}-${address}`;

const getCachedData = (key) => {
  const cached = cache.get(key);
  if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
    return cached.data;
  }
  return null;
};

const setCachedData = (key, data) => {
  cache.set(key, {
    data,
    timestamp: Date.now()
  });
};

// Helper to get cached table data
const getCachedTableData = (network, appId, page) => {
  const key = `table_${network}_${appId}_${page}`;
  const cached = cache.get(key);
  if (cached && Date.now() - cached.timestamp < TABLE_CACHE_DURATION) {
    return cached.data;
  }
  return null;
};

// Helper to cache table data
const cacheTableData = (network, appId, page, data) => {
  const key = `table_${network}_${appId}_${page}`;
  cache.set(key, {
    data,
    timestamp: Date.now()
  });
};

// Fetch blob data for a specific rollup
const fetchRollupBlobData = async (network, appId) => {
  const cacheKey = `blob-data-${network}-${appId}`;
  const cached = getCachedData(cacheKey);
  if (cached) return cached;

  try {
    const indexerUrl = network === 'mainnet' ? 'https://indexer.avail.so/' : 'https://turing-indexer.avail.so/';
    const query = `{
      dataSubmissions(
        filter: { 
          appId: { equalTo: ${appId} }
        }
      ) {
        aggregates {
          sum {
            byteSize
          }
        }
      }
    }`;

    const response = await axios.post(indexerUrl, {
      query
    });

    const result = response.data.data;
    if (result.errors) return "0 Bytes";

    const data = result.dataSubmissions;
    if (!data?.aggregates?.sum?.byteSize) return "0 Bytes";

    const byteSize = data.aggregates.sum.byteSize.toString();
    const formattedSize = formatBytes(parseInt(byteSize, 10));
    
    setCachedData(cacheKey, formattedSize);
    return formattedSize;
  } catch (error) {
    return "0 Bytes";
  }
};

// Fetch total stats with caching
const fetchTotalStats = async (network, appId = null) => {
  console.log('Fetching total stats for:', { network, appId });
  
  const cacheKey = appId ? `total-stats-${network}-${appId}` : `total-stats-${network}`;
  const cached = getCachedData(cacheKey);
  if (cached) {
    console.log('Returning cached data:', cached);
    return cached;
  }

  const graphqlEndpoint = getGraphQLEndpoint(network);
  const query = `{
    dataSubmissions${appId ? `(filter: { appId: { equalTo: ${appId} } })` : ''} {
      totalCount
      aggregates {
        distinctCount {
          id
        }
        sum {
          byteSize
          fees
        }
      }
    }
  }`;

  try {
    console.log('Making GraphQL request to:', graphqlEndpoint);
    console.log('Query:', query);
    
    const response = await axios.post(graphqlEndpoint, { query });
    console.log('Raw GraphQL response:', response.data);
    
    const stats = response.data.data.dataSubmissions;
    console.log('Parsed stats:', stats);
    
    // Handle large numbers properly
    const byteSize = stats.aggregates.sum.byteSize ? stats.aggregates.sum.byteSize.toString() : "0";
    const fees = stats.aggregates.sum.fees || 0;
    
    console.log('Processing values:', {
      byteSize,
      fees,
      distinctCount: stats.aggregates.distinctCount.id
    });
    
    const result = {
      totalSubmissions: parseInt(stats.aggregates.distinctCount.id) || 0,
      totalSize: formatBytes(byteSize),
      totalCost: formatAvailValue(fees)
    };

    console.log('Final formatted result:', result);
    
    setCachedData(cacheKey, result);
    return result;
  } catch (error) {
    console.error('Error fetching total stats:', error);
    return {
      totalSubmissions: 0,
      totalSize: '0 B',
      totalCost: '0 AVAIL'
    };
  }
};

// Fetch data for all configured rollups in a network
const fetchAllRollupsData = async (network) => {
  const rollups = ROLLUPS_CONFIG[network];
  const results = [];

  for (const rollup of rollups) {
    try {
      const blobSize = await fetchRollupBlobData(network, rollup.appId);
      results.push({
        ...rollup,
        blobsSubmitted: blobSize
      });
    } catch (error) {
      results.push({
        ...rollup,
        blobsSubmitted: '0 Bytes'
      });
    }
  }

  return results;
};

// Fetch data for a specific appID/Rollup
const fetchRollupData = async (network, appId = null) => {
  const graphqlEndpoint = getGraphQLEndpoint(network);
  
  // Base query to fetch all rollups if no appId is provided
  let query = `
    query {
      rollups {
        id
        name
        appId
        totalBlocks
        totalTransactions
        lastBlockTime
      }
    }
  `;

  // If appId is provided, modify query to fetch specific rollup
  if (appId) {
    query = `
      query {
        rollups(where: { appId: "${appId}" }) {
          id
          name
          appId
          totalBlocks
          totalTransactions
          lastBlockTime
        }
      }
    `;
  }

  try {
    const response = await axios.post(graphqlEndpoint, { query });
    return response.data.data.rollups;
  } catch (error) {
    throw error;
  }
};

const ROLLUP_STATS_QUERY = `
  query GetRollupStats($appId: Int!) {
    dataSubmissions(
      filter: {
        appId: { equalTo: $appId }
      }
    ) {
      totalCount
      aggregates {
        sum {
          byteSize
          fees
        }
      }
    }
  }
`;

const ROLLUP_TABLE_QUERY = `
  query GetRollupTableData($appId: Int!, $first: Int!, $offset: Int!) {
    dataSubmissions(
      filter: {
        appId: { equalTo: $appId }
      }
      orderBy: TIMESTAMP_DESC
      first: $first
      offset: $offset
    ) {
      totalCount
      nodes {
        id
        byteSize
        timestamp
        extrinsicId
      }
    }
  }
`;

const EXTRINSICS_QUERY = `
  query GetExtrinsics($ids: [String!]) {
    extrinsics(
      filter: {
        id: { in: $ids }
        module: { equalTo: "dataAvailability" }
        call: { equalTo: "submitData" }
      }
    ) {
      nodes {
        id
        txHash
        block {
          number
        }
        timestamp
      }
    }
  }
`;

const fetchRollupDetails = async (network, appId, address, page = 1, pageSize = 5) => {
  const endpoint = network === 'testnet' 
    ? 'https://turing-indexer.avail.so/'
    : 'https://indexer.avail.so/';

  try {
    // Check cache first for table data
    const cachedData = getCachedTableData(network, appId, page);
    if (cachedData) {
      return cachedData;
    }

    // Fetch total stats (without signer filter)
    const statsResponse = await axios.post(endpoint, {
      query: ROLLUP_STATS_QUERY,
      variables: {
        appId: parseInt(appId)
      }
    });

    // Fetch data submissions first
    const dataResponse = await axios.post(endpoint, {
      query: ROLLUP_TABLE_QUERY,
      variables: {
        appId: parseInt(appId),
        first: pageSize,
        offset: (page - 1) * pageSize
      }
    });

    const { dataSubmissions: totalStats } = statsResponse.data.data;
    const { dataSubmissions } = dataResponse.data.data;

    // Get all extrinsic IDs from the data submissions
    const extrinsicIds = dataSubmissions.nodes.map(node => node.id);

    // Fetch matching extrinsics using the IDs
    const extrinsicsResponse = await axios.post(endpoint, {
      query: EXTRINSICS_QUERY,
      variables: {
        ids: extrinsicIds
      }
    });

    const { extrinsics } = extrinsicsResponse.data.data;

    // Create a map of extrinsics by ID for O(1) lookup
    const extrinsicsById = new Map();
    extrinsics.nodes.forEach(extrinsic => {
      extrinsicsById.set(extrinsic.id, extrinsic);
    });

    // Process the table data with ID matching
    const result = {
      data: dataSubmissions.nodes.map(submission => {
        const matchingExtrinsic = extrinsicsById.get(submission.id);
        
        return {
          appId,
          block: matchingExtrinsic?.block?.number,
          txHash: matchingExtrinsic?.txHash,
          blobSize: submission.byteSize,
          timestamp: submission.timestamp
        };
      }),
      totalCount: dataSubmissions.totalCount,
      stats: {
        totalSubmissions: totalStats.totalCount.toString(),
        totalSize: formatBytes(totalStats.aggregates?.sum?.byteSize || 0),
        totalCost: formatAvailValue(totalStats.aggregates?.sum?.fees || 0)
      }
    };

    // Cache the result
    cacheTableData(network, appId, page, result);
    return result;
  } catch (error) {
    console.error('Error fetching rollup details:', error);
    throw error;
  }
};

// Date utility functions
const formatDateForQuery = (date) => {
  return date.toISOString().split('.')[0] + 'Z';
};

const getDayRange = (daysAgo) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - daysAgo);
  
  const start = formatDateForQuery(date);
  date.setHours(23, 59, 59, 999);
  const end = formatDateForQuery(date);
  
  return { start, end };
};

const generateDailyStatsQuery = (appId, network) => {
  const numericAppId = parseInt(appId, 10);
  if (isNaN(numericAppId)) return null;

  let query = '{';
  
  // Generate queries for last 7 days
  for (let i = 6; i >= 0; i--) {
    const { start, end } = getDayRange(i);
    query += `
    day${i + 1}: dataSubmissions(
      filter: {
        appId: { equalTo: ${numericAppId} }
        timestamp: {
          greaterThanOrEqualTo: "${start}"
          lessThanOrEqualTo: "${end}"
        }
      }
    ) {
      aggregates {
        sum {
          byteSize
          fees
        }
      }
    }`;
  }
  
  query += '}';
  return query;
};

// Cache key for daily stats
const getDailyStatsCacheKey = (network, appId) => `daily-stats-${network}-${appId}`;

// Fetch and process daily stats
const fetchDailyStats = async (appId, network) => {
  const cacheKey = getDailyStatsCacheKey(network, appId);
  const cachedData = getCachedData(cacheKey);
  
  if (cachedData) {
    return cachedData;
  }

  const query = generateDailyStatsQuery(appId, network);
  if (!query) return null;

  try {
    const indexerUrl = network === 'mainnet' ? 'https://indexer.avail.so/' : 'https://turing-indexer.avail.so/';
    const response = await axios.post(indexerUrl, {
      query
    });

    const result = response.data.data;
    
    if (result.errors) {
      console.error('Error fetching daily stats:', result.errors);
      return null;
    }

    // Process the data into a format suitable for charts
    const processedData = {
      blobSizes: [],
      fees: []
    };

    for (let i = 7; i >= 1; i--) {
      const dayData = result[`day${i}`];
      const date = new Date();
      date.setDate(date.getDate() - (i - 1));
      date.setHours(0, 0, 0, 0);

      const byteSize = parseInt(dayData?.aggregates?.sum?.byteSize || '0', 10);
      const fees = dayData?.aggregates?.sum?.fees || 0;

      processedData.blobSizes.push({
        date: date.toISOString(),
        value: byteSize
      });

      processedData.fees.push({
        date: date.toISOString(),
        value: parseFloat(fees.toFixed(2))
      });
    }

    // Cache the processed data
    setCachedData(cacheKey, processedData);

    return processedData;
  } catch (error) {
    console.error('Error fetching daily stats:', error);
    return null;
  }
};

export {
  fetchRollupDetails,
  fetchDailyStats,
  fetchAllRollupsData,
  fetchTotalStats,
  fetchRollupData,
  formatAvailValue,
  getCacheKey,
  getCachedData,
  getDailyStatsCacheKey
};
