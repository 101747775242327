import { ROLLUPS_CONFIG } from '../config/rollups';

// Storage keys for different data types
export const STORAGE_KEYS = {
  DASHBOARD_DATA: (network) => `${network}_dashboard_data`,
  LAST_UPDATED: (network) => `${network}_last_updated`,
  ROLLUP_STATS: (network, appId) => `${network}_${appId}_stats`,
  ALL_ROLLUPS_STATS: (network) => `${network}_all_rollups_stats`,
  DAILY_STATS: (network, appId) => `${network}_${appId}_daily_stats`
};

// Default values for rollup stats
export const DEFAULT_ROLLUP_STATS = {
  totalSubmissions: '0',
  totalSize: '0 Bytes',
  totalCost: '0 AVAIL',
  lastUpdated: new Date().toISOString()
};

// Generate default stats for all configured rollups
export const generateDefaultRollupsStats = () => {
  const stats = {
    mainnet: {},
    testnet: {}
  };

  // Initialize stats for all rollups
  Object.entries(ROLLUPS_CONFIG).forEach(([network, rollups]) => {
    rollups.forEach(rollup => {
      stats[network][rollup.appId] = {
        ...DEFAULT_ROLLUP_STATS,
        name: rollup.name,
        address: rollup.address
      };
    });
  });

  return stats;
};

// Default cached data structure
export const DEFAULT_CACHED_DATA = {
  mainnet: {
    stats: {
      totalSize: '0 MB',
      totalCost: '0 AVAIL',
      totalSubmissions: '0'
    },
    rollups: [],
    timestamp: new Date().toISOString(),
    rollupsStats: generateDefaultRollupsStats().mainnet
  },
  testnet: {
    stats: {
      totalSize: '0 MB',
      totalCost: '0 AVAIL',
      totalSubmissions: '0'
    },
    rollups: [],
    timestamp: new Date().toISOString(),
    rollupsStats: generateDefaultRollupsStats().testnet
  }
};

// Helper to get stored rollup stats
export const getStoredRollupStats = (network, appId) => {
  try {
    // First try to get from all rollups stats
    const allStats = localStorage.getItem(STORAGE_KEYS.ALL_ROLLUPS_STATS(network));
    if (allStats) {
      const parsed = JSON.parse(allStats);
      if (parsed[appId]) {
        return parsed[appId];
      }
    }

    // Fallback to individual rollup stats (legacy support)
    const stored = localStorage.getItem(STORAGE_KEYS.ROLLUP_STATS(network, appId));
    if (stored) {
      return JSON.parse(stored);
    }

    // Return default stats from the default cached data
    return DEFAULT_CACHED_DATA[network].rollupsStats[appId] || DEFAULT_ROLLUP_STATS;
  } catch {
    return DEFAULT_ROLLUP_STATS;
  }
};

// Helper to store rollup stats
export const storeRollupStats = (network, appId, stats) => {
  try {
    // Update the individual rollup stats
    const statsWithTimestamp = {
      ...stats,
      lastUpdated: new Date().toISOString()
    };

    // Update in all rollups stats
    const allStats = localStorage.getItem(STORAGE_KEYS.ALL_ROLLUPS_STATS(network)) || '{}';
    const parsedAllStats = JSON.parse(allStats);
    parsedAllStats[appId] = statsWithTimestamp;
    localStorage.setItem(STORAGE_KEYS.ALL_ROLLUPS_STATS(network), JSON.stringify(parsedAllStats));

    // Also store individually for legacy support
    localStorage.setItem(
      STORAGE_KEYS.ROLLUP_STATS(network, appId),
      JSON.stringify(statsWithTimestamp)
    );
  } catch (error) {
    console.error('Error storing rollup stats:', error);
  }
};

// Helper to get stored daily stats
export const getStoredDailyStats = (network, appId) => {
  try {
    const stored = localStorage.getItem(STORAGE_KEYS.DAILY_STATS(network, appId));
    if (stored) {
      return JSON.parse(stored);
    }
    return null;
  } catch {
    return null;
  }
};

// Helper to store daily stats
export const storeDailyStats = (network, appId, stats) => {
  try {
    localStorage.setItem(STORAGE_KEYS.DAILY_STATS(network, appId), JSON.stringify({
      ...stats,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error storing daily stats:', error);
  }
};

// Helper to get stored dashboard data
export const getStoredDashboardData = (network) => {
  try {
    const stored = localStorage.getItem(STORAGE_KEYS.DASHBOARD_DATA(network));
    if (stored) {
      return JSON.parse(stored);
    }
    return DEFAULT_CACHED_DATA[network];
  } catch {
    return DEFAULT_CACHED_DATA[network];
  }
};

// Helper to store dashboard data
export const storeDashboardData = (network, data) => {
  try {
    const dataToStore = {
      ...data,
      timestamp: new Date().toISOString()
    };
    localStorage.setItem(STORAGE_KEYS.DASHBOARD_DATA(network), JSON.stringify(dataToStore));
  } catch (error) {
    console.error('Error storing dashboard data:', error);
  }
};

// Generate mock daily stats for initial load
export const generateInitialDailyStats = () => {
  const now = new Date();
  const stats = {
    blobSizes: [],
    fees: []
  };

  for (let i = 6; i >= 0; i--) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    date.setHours(0, 0, 0, 0);

    stats.blobSizes.push({
      date: date.toISOString(),
      value: 0
    });

    stats.fees.push({
      date: date.toISOString(),
      value: 0
    });
  }

  return stats;
};
